<template>
	<div id="nav">
		<!-- <router-link to="/">Home</router-link> |
		<router-link to="/sondage_jeux">Sondage Soirée Jeux</router-link> -->
	</div>
	<router-view />
</template>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #d4e686;
	/* color: #b0c9e2; */
	background-color: black;
}

#nav {
	/* padding: 30px; */
}

#nav a {
	font-weight: bold;
	color: #4d6781;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
</style>
